import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import { Hierarchy } from '../AppStore/Slices/PublicationSlice';

const headers: AxiosRequestConfig = {
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    },
    responseType: 'json'
};

const getApiBaseAddress = (): string => {
    let result = window.location.origin;

    if (result.includes('localhost')) {
        result = 'http://www.ultimatesoftwaresolutions.com:666'; // http://localhost:25345
    }

    return result + '/api/';
}

const client = axios.create({
    baseURL: getApiBaseAddress(),
    headers: { 'Accept': 'application/json' }
});

// Authentication
export const silentLogin = (ipAddress: string | null) => client.get('silentLogin/' + (ipAddress ? encodeURI(ipAddress) : ''));

export const userLogin = (username: string, password: string, rememberMe: boolean) => {
    const url = `login/${encodeURI(username)}` + (rememberMe ? `?rememberMe=${rememberMe}` : '')
    return client.post(url, `"${password}"`, headers);
}

// Export
export const exportDocument = async (userToken: string, sourceGuid: string, documentGuid: string, showAnnotations: boolean, contentType: string) => {
    if (userToken === null) return null;

    const postBody =
    {
        contentType,
        includeAnnotations: showAnnotations,
        includeHeaders: true,
        includeLinks: false,
        includePagesAsImages: false,
        includeWatermark: true,
        exportSourceDocuments: [
            {
                sourceGuid,
                documentGuids: [`${documentGuid}`]
            }
        ]
    }

    try {
        var response = await client.post('documentExport/' + encodeURI(userToken), postBody,
            { headers: { 'Content-Type': 'application/json-patch+json; charset=utf-8' }, responseType: 'blob' })

        var filename = 'OptimaDocument'
        var contentDisposition = response.request.getResponseHeader('Content-Disposition')

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            var matches = filenameRegex.exec(contentDisposition)
            if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
            }
        }

        downloadFile(response, filename, contentType)
    } catch (e) {
        console.log(e)
        return false
    }

    return true
}

const downloadFile = (response: AxiosResponse<any>, filename: string, contentType: string) => {
    // It is necessary to create a new blob object with mime-type explicitly set
    // otherwise only Chrome works like it should
    var newBlob = new Blob([response.data], { type: `${contentType}` })

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(newBlob)
    var link = document.createElement('a')
    link.href = data
    link.download = filename
    link.click()
    setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data)
    }, 100)
}

// User Library
export const fetchUserLibrary = async (userToken: string) => {
    if (userToken === null) return { data: [] };
    return client.get('library/' + encodeURI(userToken), headers);
}

// Publication Search
export const search = async (userToken: string, searchText: string, where: string | null) => {
    if (userToken === null) return null

    const url = 'search/' + encodeURI(userToken) + '/' + encodeURIComponent(searchText) + (where ? `?where=${where}` : '');

    return client.get(url, headers);
}

// Publication Content
export const getDocumentContent = async (userToken: string, sourceId: string, documentId: string, searchText?: string) => {
    if (userToken === null) return null;
    let url = 'documentContent/' + encodeURI(userToken + '/' + sourceId + '/' + documentId);
    if (searchText && searchText.length) {
        url += '?searchText=' + searchText;
    }
    return client.get(url);
}

export const getDocumentProperties = (userToken: string, sourceId: string, documentId: string) => {
    if (userToken === null) return null
    return client.get('document/' + encodeURI(userToken + '/' + sourceId + '/' + documentId));
}

// Hierarchy Content
export const getDocumentHierarchy = (userToken: string, sourceId: string, hierarchyId: string, depth: number) => {
    if (userToken === null) return null;
    return client.get<Hierarchy[] | null>('hierarchy/' + encodeURI(userToken + '/' + sourceId + '/' + hierarchyId + '?depth=' + depth));
}

export const getDocumentHierarchyPath = (userToken: string, sourceId: string, documentId: string) => {
    if (userToken === null) return null;
    return client.get('hierarchyPath/' + encodeURI(userToken + '/' + sourceId + '/' + documentId));
}
