import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
    collapseOrExpandHierarchy,
    expandHierarchy,
    fetchHierarchy,
    getPublication,
    getPublicationHierarchy,
    selectHierarchyNode
} from '../../AppStore/Slices/PublicationSlice';
import PublicationHierarchyNode from './PublicationHierarchyNode';

const PublicationHierarchy = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const publicationRef = React.useRef<HTMLDivElement>(null);

    const { documentId, sourceId } = useParams<{ sourceId: string, documentId: string | undefined }>();

    const hierarchy = useSelector(getPublicationHierarchy(sourceId));
    const publication = useSelector(getPublication(sourceId));
    const [currentDocumentId, setCurrentDocumentId] = useState<string | null>(null);

    const isInViewport = (el: HTMLElement): boolean => {
        if (!publicationRef.current) {
            return false;
        }

        const bounding = el.getBoundingClientRect();
        const boundingPublication = publicationRef.current.getBoundingClientRect();

        return bounding.top >= boundingPublication.y && bounding.bottom <= (boundingPublication.y + boundingPublication.height);
    }

    useEffect(() => {
        if (publication) {
            dispatch(fetchHierarchy(sourceId));

            if (documentId &&
                currentDocumentId !== documentId) {
                setCurrentDocumentId(documentId);
                dispatch(selectHierarchyNode(sourceId, documentId));

                setTimeout(() => {
                    const hierarchyNode = document.getElementById(documentId);
                    if (hierarchyNode && !isInViewport(hierarchyNode)) {
                        hierarchyNode.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
                    }
                }, 500);
            }
        }
    }, [currentDocumentId, dispatch, documentId, publication, publicationRef, sourceId])

    const onExpandOrCollapse = (updatedDocumentId: string) => {
        dispatch(collapseOrExpandHierarchy(sourceId, updatedDocumentId));
    }

    const onSelect = (updatedDocumentId: string) => {
        if (documentId !== updatedDocumentId) {
            setCurrentDocumentId(updatedDocumentId);
            dispatch(expandHierarchy(sourceId, updatedDocumentId));
            history.push(`/publication/${sourceId}/${updatedDocumentId}`);
        }
    }

    return (
        <div className="card flex-down full-height">
            <div className="card-body card-body-flush flex-me v-scroll tree-view-container" ref={publicationRef}>
                <ul className='tree-view'>
                    {hierarchy && hierarchy.length > 0 &&
                        <PublicationHierarchyNode node={hierarchy[0]} onExpandOrCollapse={onExpandOrCollapse}
                            onSelect={onSelect} selectedDocumentId={documentId} id={hierarchy[0].documentGuid} />}
                </ul>
            </div>
        </div>
    )
}

export default PublicationHierarchy;
