import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { getSearch, setSearch } from '../../AppStore/Slices/UISlice';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { Button, InputGroup } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import searchData from './SearchData';

interface ISearchBarProps {
    initialSearchText?: string;
    searchResults?: boolean;
};

const ActiveIndexWatcher = ({ update }: { update: () => void }) => {
    useEffect(update);
    return null;
};

const useQueryString = () => new URLSearchParams(useLocation().search);

const SearchBar = ({ initialSearchText, searchResults }: ISearchBarProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const queryString = useQueryString();

    const typeaheadRef = React.createRef<Typeahead<string>>();
    const searchContext = useSelector(getSearch);
    const defaultInputValue = initialSearchText ?? (searchContext && searchContext.searchText.length ? searchContext.searchText : '');
    const where = queryString.get('where');

    const [activeIndex, setActiveIndex] = useState(-1);
    const [searchIsItInforce, setSearchIsItInforce] = useState<boolean>(searchContext.searchWhere.IsItInforce);
    const [searchJudgments, setSearchJudgments] = useState<boolean>(searchContext.searchWhere.Judgments);
    const [searchRegulations, setSearchRegulations] = useState<boolean>(searchContext.searchWhere.Regulations);
    const [searchStatutes, setSearchStatutes] = useState<boolean>(searchContext.searchWhere.Statutes);
    const [searchText, setSearchText] = useState<string>(defaultInputValue);

    useEffect(() => {
        if (where) {
            setSearchIsItInforce(where.includes("IsItInforce"));
            setSearchJudgments(where.includes("Judgments"));
            setSearchRegulations(where.includes("Regulations"));
            setSearchStatutes(where.includes("Statutes"));
        }
    }, [where]);

    const handleSearch = () => {
        if (searchText.length) {
            dispatch(setSearch({
                searchText, searchWhere: {
                    IsItInforce: searchIsItInforce,
                    Judgments: searchJudgments,
                    Regulations: searchRegulations,
                    Statutes: searchStatutes
                }
            }));

            let term: string = encodeURIComponent(searchText);
            let where: string | null = null;

            if (!searchText.includes('source:')) {

                where = '';

                if (searchStatutes) {
                    where += 'Statutes'
                }

                if (searchRegulations) {
                    if (where.length) {
                        where += '|';
                    }
                    where += 'Regulations'
                }

                if (searchJudgments) {
                    if (where.length) {
                        where += '|';
                    }
                    where += 'Judgments'
                }

                if (searchIsItInforce) {
                    if (where.length) {
                        where += '|';
                    }
                    where += 'IsItInforce'
                }
            }

            const url = `/search/${term}` + (where ? `?where=${where}` : '');
            history.push(url);
        }
    }

    const onChange = (selected: string[]) => {
        const match = searchData.find(item => item.title === selected[0]);
        if (match) {
            history.push(`/publication/a4637979-4503-4dc4-8613-63d908f1077b/${match.guid}`);
        }
    }

    const onKeyDown = (event: any) => {
        if (event.keyCode === 13 && -1 === activeIndex) {
            typeaheadRef.current?.hideMenu();
            handleSearch();
        }
    }

    const onInputChange = (input: string, e: Event) => {
        setSearchText(input);
    }

    return (
        <div className="form-group">
            <div className="input-group nowrap">
                <InputGroup>
                    <Typeahead
                        autoFocus={!searchResults}
                        id="search-text"
                        emptyLabel="No enactments matched!"
                        minLength={3}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        onInputChange={onInputChange}
                        options={searchData.map(x => x.title)}
                        placeholder="Enter search text..."
                        ref={typeaheadRef}
                        defaultInputValue={defaultInputValue}
                    >
                        {({ activeIndex }: { activeIndex: number }) => (<ActiveIndexWatcher update={() => setActiveIndex(activeIndex)} />)}
                    </Typeahead>
                    <InputGroup.Append>
                        <Button onClick={handleSearch} variant="outline-secondary">
                            <FontAwesomeIcon icon={faSearch as IconProp} />
                        </Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>
            <div style={{ paddingTop: '0.5rem' }}>
                <span style={{ color: 'gray' }}>Search through:</span>
                <input style={{ margin: '0 0.75rem' }} type="checkbox" checked={searchStatutes} onChange={() => setSearchStatutes(!searchStatutes)} />
                <label htmlFor="statutes">Statutes</label>
                <input style={{ margin: '0 0.75rem' }} type="checkbox" checked={searchRegulations} onChange={() => setSearchRegulations(!searchRegulations)} />
                <label htmlFor="regulations">Regulations</label>
                <input style={{ margin: '0 0.75rem' }} type="checkbox" checked={searchJudgments} onChange={() => setSearchJudgments(!searchJudgments)} />
                <label htmlFor="judgments">Judgments</label>
                <input style={{ margin: '0 0.75rem' }} type="checkbox" checked={searchIsItInforce} onChange={() => setSearchIsItInforce(!searchIsItInforce)} />
                <label htmlFor="isitinforce">Is it Inforce?</label>
            </div>
        </div>
    );
}

export default SearchBar;
